import request from '@/utils/request'
import qs from 'qs'

// 获取专题和二级专题
export function getSpec() {
  return request({
    url: '/product/spec',
    method: 'post'
  })
}

// 根据二级专题查找科目
export function getSubject(data) {
  return request({
    url: '/product/course',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 按科目和试卷类型查找所属的试卷
export function getPaper(data) {
  return request({
    url: '/ee/getTestPaperByCourseIDType',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 根据试卷的ID获取试题
export function getPaperTest(data) {
  return request({
    url: '/ee/getTest',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课程订单中是否有密押试卷返回密押试卷的信息
export function getSecretPaper(data) {
  return request({
    url: '/ee/isSecretTest',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 课程订单中的试卷
export function getOrderPaper(data) {
  return request({
    url: '/ee/sectest',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 根据二级专题查找题库
export function getTestGood(data) {
  return request({
    url: '/ee/getTestGood',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 通过题库套餐查找所属的所有试卷
export function getComboPaperList(data) {
  return request({
    url: '/ee/getExam',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 通过题库套餐查找所属的所有试卷带自身信息
export function getTestGoodPaper(data) {
  return request({
    url: '/ee/getExamInfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 收藏试题
export function doCollectTest(data) {
  return request({
    url: '/user/collect',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 取消收藏试题
export function doCancleCollectTest(data) {
  return request({
    url: '/user/removeCollect',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 提交试卷
export function doSignTest(data) {
  return request({
    url: '/user/signTest',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取做题的结果
export function getWrongTest(data) {
  return request({
    url: '/user/getWrongTest',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取收藏题的结果
export function getMyCollectTest(data) {
  return request({
    url: '/user/myCollTests',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 通过资格证查找一般试卷
export function getPaperBySpecId(data) {
  return request({
    url: '/ee/getCommonExamBySpecID',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 考试分类
export function getSingleSpec(data) {
  return request({
    url: '/product/singleSpec',
    method: 'post',
    data:qs.stringify(data)
  })
}

// 请求广告
export function getAdvert(data) {
  return request({
    url: '/Advert/getByPid',
    method: 'post',
    data:qs.stringify(data)
  })
}
