<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <div class="container">
      <el-row :gutter="10">
        <el-col :span="18">
          <div class="bank-info">
            <!-- 题库信息 -->
            <div class="bank-info-header">
              <div class="header-top">
                <div class="header-top-img">
                  <img :src="testGood.thumb" alt="">
                </div>
                <div class="header-top-info">
                  <div class="info-title">{{ testGood.title }}</div>
                  <div class="info-num">试卷数量：{{ testGood.exam_num }}套</div>
                </div>
              </div>
              <div class="header-buttom">
                <div class="header-buttom-price">￥{{ testGood.sale_price }}</div>
                <div class="header-buttom-btn" v-if="!isHava" @click="goCreateOrder">开通题库</div>
                <div class="header-buttom-btn" v-else>已开通</div>
              </div>
            </div>
            <!-- 试卷列表 -->
            <div class="bank-paper-list">
              <div class="paper-list-row">
                <div class="paper-list-row-name">试卷目录</div>
                <div class="paper-list-row-btn">做题</div>
              </div>
              <div class="paper-list-row" v-for="paper in testGood.exam" :key="paper.id">
                <div class="paper-list-row-name">{{ paper.title }}</div>
                <div class="paper-list-row-btn" @click="goQuestion(paper)">
                  <span v-if="!isHava"><i class="el-icon-lock"></i></span>
                  <span v-else>开始做题</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <!-- 相关题库 -->
          <div class="bank-about">
            <div class="bank-about-title"><div class="line"></div><div>相关题库</div></div>
            <div class="bank-about-item" v-for="bank in relevantList" :key="bank.id">
              <div class="item-title">{{ bank.title }}</div>
              <div class="item-bottom">
                <div class="item-bottom-price">￥{{ bank.sale_price }}</div>
                <div class="item-bottom-btn" @click="goSecretDetail(bank)">开通题库</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import { getTestGood, getTestGoodPaper } from "@/api/exam"
import { getLocalStorage, setSessionStorage, getSessionStorage } from '@/utils/auth'
import { myOrderAll } from '@/api/user'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
  },
  data() {
    return {
      // 当前密押试卷
      currentTestGood: {},
      // 当前密押试卷信息及试卷列表
      testGood: {},
      // 当前题库是否拥有
      isHava: false,
      // 相关题库
      relevantList: [],

    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.currentTestGood = getSessionStorage("currentTestGood")
      getTestGoodPaper({goods_id: this.currentTestGood.id}).then((res) => {
        if (res.code == 1) {
          this.testGood = res.data;
          this.getRelevantList()
        }
      })
      if (getLocalStorage('token')) {
        myOrderAll().then(res => {
          if (res.code == 1) {
            res.data[0].forEach(item => {
              if (item.product_id == this.currentTestGood.id) {
                this.isHava = true
                return
              }
            })
          }
        })
      }
    },
    // 获取相关题库
    getRelevantList() {
      getTestGood({spec_id: this.currentTestGood.spec_id}).then((res) => {
        if (res.code == 1) {
          this.relevantList = []
          res.data.forEach((item) => {
            if (item.id != this.currentTestGood.id) {
              this.relevantList.push(item)
            }
          })
        }
      })
    },
    // 做题
    goQuestion(paper) {
      if (this.isHava) {
        setSessionStorage('currentPaper', paper)
        this.$router.push('/question')
      } else {
        this.$message("题库尚未开通")
      }
    },
    // 切换题库
    goSecretDetail(bank) {
      setSessionStorage("currentTestGood", bank)
      window.open('/secretdetail')
    },
    // 创建订单
    goCreateOrder() {
      if (getLocalStorage('token')) {
        const orderInfo = {
          ...this.currentTestGood,
          title: this.currentTestGood.title
        }
        setSessionStorage('createOrderInfo', orderInfo)
		console.log(orderInfo)
        this.$router.push('/order/' + this.currentTestGood.id + '/3')
      } else {
        this.$notify.error({
          title: '未登录',
          message: '请先登录'
        })
      }
    },

  },
};
</script>

<style scoped lang="scss">
.el-row {
  margin: 30px 0;
}

/* 题库信息 begin */
.bank-info-header {
  background-color: $background-color;
  box-shadow: 0 1px 2px rgb(0 0 0 / 16%);
  padding: 10px;
  .header-top {
    display: flex;
    .header-top-img {
      width: 130px;
      margin-right: 10px;
    }
    .header-top-info {
      width: 100%;
      .info-title {
        font-size: 20px;
        font-weight: 700;
      }
      .info-num {
        color: #999;
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
}
.bank-info-header .header-buttom {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bank-info-header .header-buttom > .header-buttom-price {
  color: $price-color;
  font-size: 24px;
}
.bank-info-header .header-buttom > .header-buttom-btn {
  color: #fff;
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  border-radius: 5px;
  background-color: $theme-color;
  cursor: pointer;
}
/* 题库信息 end */

/* 试卷列表 begin */
.bank-paper-list {
  margin-top: 50px;
}
.bank-paper-list .paper-list-row {
  color: #666;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  background-color: $background-color;
  border: 1px solid #eee;
  padding: 0 10px 0 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.bank-paper-list .paper-list-row > .paper-list-row-name {
  width: 80%;
}
.bank-paper-list .paper-list-row > .paper-list-row-btn {
  width: 20%;
  text-align: center;
}
.bank-paper-list .paper-list-row:not(:first-child) > .paper-list-row-btn {
  height: 40px;
  line-height: 40px;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
}
.bank-paper-list .paper-list-row:first-child {
  height: 85px;
  line-height: 85px;
  font-size: 18px;
  font-weight: 700;
  border: none;
}
.bank-paper-list .paper-list-row:first-child > .paper-list-row-name {
  text-indent: 160px;
}
.bank-paper-list .paper-list-row:not(:first-child):hover {
  background-color: #f1f1f1;
}
/* 试卷列表 end */

/* 相关题库 begin */
.bank-about {
  min-height: 300px;
  background-color: $background-color;
  border: 1px solid #f1f1f1;
  box-shadow: 0 2px 5px hsl(0deg 0% 65% / 16%);
  border-radius: 5px;
  padding: 10px;
}
.bank-about .bank-about-title {
  height: 67px;
  line-height: 67px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #f1f1f1;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.bank-about .bank-about-title > .line {
  width: 3px;
  height: 20px;
  background-color: $theme-color;
  margin-right: 5px;
}
.bank-about .bank-about-item {
  height: 100px;
  border: 1px solid #eee;
  box-shadow: 0 3px 6px hsl(0deg 0% 65% / 16%);
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bank-about .bank-about-item > .item-title {
  color: #666;
  font-size: 16px;
}
.bank-about .bank-about-item > .item-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.bank-about .bank-about-item > .item-bottom .item-bottom-price {
  color: $price-color;
  font-size: 20px;
}
.bank-about .bank-about-item > .item-bottom .item-bottom-btn {
  color: $danger-color;
  width: 120px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  text-align: center;
  border: 1px solid $danger-color;
  border-radius: 5px;
  cursor: pointer;
}
.bank-about .bank-about-item:hover .item-bottom-btn {
  color: #fff;
  background-color: $theme-color;
  border: none;
}
/* 相关题库 end */
</style>
